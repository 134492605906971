import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const QueriesToTransfer = ['ReturnUrl', 'returnUrl'];

const useLoginNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    /** Navigates with important returnUrl in react router, the ReturnUrl is where the state is when signing in */
    const navigateWithQuery = useCallback(
        (route: string) => {
            const searchParams = new URLSearchParams(location.search);
            const newSearchParams = new URLSearchParams();
            QueriesToTransfer.forEach((queryKey) => {
                if (searchParams.has(queryKey)) {
                    newSearchParams.set(queryKey, searchParams.get(queryKey)!);
                }
            });
            navigate({ pathname: route, search: newSearchParams.toString() });
        },
        [location.search, navigate],
    );
    return navigateWithQuery;
};

export namespace SpaRoutes {
    export const ForgotPassword = {
        Start: '/glomt-losenord',
        Company: '/glomt-losenord/foretag',
    };
    export const Confirm = {
        Bankid: '/bekrafta/bankid',
        Password: '/bekrafta/losenord',
    };
    export const Login = {
        Start: '/logga-in',
        Company: '/logga-in/foretag',
        RememberMe: '/logga-in/kom-ihag',
    };
    export const Logout = {
        Start: '/logga-ut',
    };
    export const ChangePassword = {
        Email: '/andra-losenord/e-post',
        Bankid: '/andra-losenord/bankid',
    };
    export const ChangeEmail = {
        Verify: (email: string) => `/andra-email/verifiera/${encodeURIComponent(email)}`,
        Email: '/andra-email/e-post',
        Bankid: '/andra-email/bankid',
        Start: '/andra-email',
    };
    export const RemoveAccount = {
        Email: '/radera-kundkonto/e-post',
        Bankid: '/radera-kundkonto/bankid',
        Done: '/radera-kundkonto/klart',
    };
    export const CreateAccount = {
        Start: '/skapa-kundkonto',
        Company: {
            Start: '/skapa-kundkonto/foretag/e-post',
            Email: {
                Verify: '/skapa-kundkonto/foretag/e-post/verifiera',
            },
        },
        Private: {
            Start: '/skapa-kundkonto/privat/',
            Email: {
                Start: '/skapa-kundkonto/privat/e-post',
                Verify: '/skapa-kundkonto/privat/e-post/verifiera',
            },
            BankId: {
                Start: '/skapa-kundkonto/privat/bankid',
                RegisterEmail: '/skapa-kundkonto/privat/bankid/registrera-epost',
                EmailAlreadyConnected: (email: string) =>
                    `/skapa-kundkonto/privat/bankid/upptagen-epost/${encodeURIComponent(email)}`,
                EmailNotConnected: (email: string) =>
                    `/skapa-kundkonto/privat/bankid/ny-epost/${encodeURIComponent(email)}`,
                VerifyExistingEmail: (email: string) =>
                    `/skapa-kundkonto/privat/bankid/upptagen-epost/${encodeURIComponent(
                        email,
                    )}/verifiera/`,
                VerifyNewEmail: (email: string) =>
                    `/skapa-kundkonto/privat/bankid/ny-epost/${encodeURIComponent(
                        email,
                    )}/verifiera/`,
            },
        },
    };
    export const BuildChallengeExternalUrl = (provider: string, returnUrl: string) =>
        `/externalauthentication/challange/${provider}?returnUrl=${encodeURIComponent(returnUrl)}`;
}

export default useLoginNavigate;
